import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SimpleSection from "../components/sections/simpleSection/SimpleSection";
import BreadCrumps from "../ui/breadCrumps/BreadCrumps";
import { HelmetDatoCms } from "gatsby-source-datocms";

const OfertaPage = ({ data }) => {
  return (
    <>
      <HelmetDatoCms seo={data.page.seo} favicon={data.site.favicon} />
      <Layout>
        <BreadCrumps title={`ПУБЛІЧНИЙ ДОГОВІР`} />
        <SimpleSection
          id="oferta"
          sectionTitle={`ПУБЛІЧНИЙ ДОГОВІР`}
          sectionTitleDesc={`про надання комплексу послуг спортивного клубу`}
          sectiontitleBtnTxt=""
          sectiontitleBtnUrl={false}
          isBlackBg={false}
        >
          <section className="html-section" style={{ padding: `34px 16px` }}>
            <div
              key={`body`}
              style={{ maxWidth: `1100px`, width: `100%` }}
              dangerouslySetInnerHTML={{ __html: data.page.htmlcontent }}
            ></div>
          </section>
        </SimpleSection>
      </Layout>
    </>
  );
};

export default OfertaPage;

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsOfertapage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      htmlcontent
    }
  }
`;
